import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/navBar/navBar";
import Footer from "./components/footer/footer";
import camel from "./assets/camel.gif";
import LiveAuction from "./pages/liveAuction/liveAuction";


// Lazy load the page components
const SignIn = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/signIn/signIn")), 1500); // Delay of 1.5 seconds
    })
);

const SignUp = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/signup/signup")), 1500);
    })
);

const OTP = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/otp/otp")), 1500);
    })
);

const Auctions = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/auctions/auctions")), 1500);
    })
);

const AboutUs = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/aboutUs/aboutUs")), 1500);
    })
);

const DashBoard = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/dashboard/dashboard")), 1500);
    })
)

const styles = {
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Full viewport height
  },
  loadingGif: {
    width: "400px", // Adjust size as needed
    height: "400px",
  },
};

function MainContent() {
  const location = useLocation();

  // Check if the current route is the live auction page
  const isLiveAuction = location.pathname.startsWith("/liveAuction");
  const isDashBoard = location.pathname.startsWith("/dashboard")
  return (
    <>
      {/* Conditionally render NavBar if not on the live auction page */}
      {!isLiveAuction && !isDashBoard && <NavBar />}

      <Suspense
        fallback={
          <div style={styles.loadingContainer}>
            <img src={camel} alt="Loading..." style={styles.loadingGif} />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/auctions" element={<Auctions />} />
          <Route path="/liveAuction/:id" element={<LiveAuction />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/dashboard" element={<DashBoard />}/>
        </Routes>
      </Suspense>

      {/* Conditionally render Footer if not on the live auction page */}
      {!isLiveAuction && !isDashBoard && <Footer />}
    </>
  );
}

export default MainContent;
