import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import liveAuctionImg from "../../assets/liveAuctionImg.png";
import { AiOutlineClose } from "react-icons/ai"; // Importing an icon for exit
import { Avatar } from "@mui/material"; // Using MUI Avatar
import exitVector from "../../assets/exitVector.png";
import walletVector from "../../assets/walletVector2.png";
import profileVector from "../../assets/profileVector.png";
import vectoryVector from "../../assets/vectoryVector.png";
import addVector from "../../assets/addVector.png";
import userProfileImg from "../../assets/nawaderIcon.jpeg";
import { io } from "socket.io-client";
import "./liveAuction.css";
import axios from "axios";
import { toast } from "react-toastify";
import cupVector from "../../assets/cupVector.png";
import productVector from "../../assets/productVector.png";
import moneyVector from "../../assets/moneyVector.png";
import AgoraRTC from "agora-rtc-sdk-ng";
import BidModal from "../../components/bidModal/bidModal";
import AwardingModal from "../../components/awardingModal/awardingModal";
import WinningModal from "../../components/winningModal/winningModal";
import LosingModal from "../../components/losingModal/losingModal";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCameraRotate,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

const LiveAuction = () => {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [auction, setAuction] = useState();
  const [comment, setComment] = useState("");
  const [socket, setSocket] = useState(null);
  const [views, setViews] = useState(0);
  const [lastBidNumber, setLastBidNumber] = useState(0);
  const [lastBidName, setLastBidName] = useState("");
  const [comments, setComments] = useState([]);
  const [showBidModal, setShowBidModal] = useState(false);
  const [showAwardingModal, setShowAwardingModal] = useState(false);
  const [visibleComments, setVisibleComments] = useState(10); // Initial number of visible comments
  const commentsRef = useRef(null); // Reference to comments container
  const [bid, setBid] = useState(0);
  const [isBroadcaster, setIsBroadcaster] = useState("");
  const localVideoRef = useRef(null);
  const [productDescription, setProductDescription] = useState("");
  const [showWinningModal, setShowWinningModal] = useState(false);
  const [showLosingModal, setShowLosingModal] = useState(false);
  const [scrollAtBottom, setScrollAtBottom] = useState(true);
  const [rtc, setRtc] = useState({
    client: null,
    localVideoTrack: null,
    localAudioTrack: null,
  });
  const [isMuted, setIsMuted] = useState(false);
  const navigate = useNavigate();
  const initAgora = async (isBroadcaster) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agora/token`,
        {
          params: {
            channelName: `auction_${id}`,
            uid: +user.id,
            isPublisher: isBroadcaster,
          },
        }
      );

      const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

      await client.setClientRole(
        isBroadcaster === "true" ? "host" : "audience"
      );

      await client.join(
        process.env.REACT_APP_AGORA_APP_ID,
        `auction_${id}`,
        response.data.data,
        user.id
      );

      if (isBroadcaster === "true") {
        const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

        await client.publish([localVideoTrack, localAudioTrack]);
        localVideoTrack.play(localVideoRef.current);

        // Update the RTC state
        setRtc({ client, localVideoTrack, localAudioTrack });
      } else {
        client.on("user-published", async (remoteUser, mediaType) => {
          await client.subscribe(remoteUser, mediaType);
          if (mediaType === "video") {
            const remoteVideoTrack = remoteUser.videoTrack;
            remoteVideoTrack.play(localVideoRef.current);
          }
          if (mediaType === "audio") {
            const remoteAudioTrack = remoteUser.audioTrack;
            remoteAudioTrack.play();
          }
        });
      }
    } catch (error) {
      console.error("Failed to join Agora channel", error);
    }
  };

  function handleComment(e) {
    e.preventDefault();
    socket.emit("comment", {
      auctionId: +id,
      userId: +user.id,
      comment: comment,
    });
    setComment("");
  }
  // Load more comments when scrolling to the top
  // Handle scroll event for loading older comments
  const handleScroll = () => {
    const commentsContainer = commentsRef.current;
    if (!commentsContainer) return;

    const top = commentsContainer.scrollTop;
    const atBottom =
      commentsContainer.scrollHeight - commentsContainer.scrollTop ===
      commentsContainer.clientHeight;

    // Load more comments if scrolled to the top
    if (top === 0 && visibleComments < comments.length) {
      setVisibleComments((prev) => Math.min(prev + 10, comments.length)); // Load 10 more comments
    }

    // Track if the user is scrolled to the bottom
    setScrollAtBottom(atBottom);
  };

  function handleBid(e) {
    e.preventDefault();

    if (+bid <= +lastBidNumber) {
      toast.error("يجب المزايدة بمبلغ اعلى من اخر مزايدة");
      return;
    }
    socket.emit("placeBid", {
      auctionId: +id,
      userId: +user.id,
      amount: +bid,
    });
    setShowBidModal(false);
    setBid(0);
  }

  function handleShowBid() {
    setShowBidModal(true);
  }

  function handleShowAwarding() {
    setShowAwardingModal(true);
  }

  function handleAwarding(e) {
    e.preventDefault();
    socket.emit("awardingAuction", {
      auctionId: +id,
      userId: +user.id,
      product: productDescription,
    });
  }

  const handleSwitchCamera = async () => {
    try {
      if (rtc.localVideoTrack) {
        console.log(rtc.localVideoTrack);

        // Find the current facing mode (assuming default is "user")
        const currentFacingMode =
          rtc.localVideoTrack._constraints.facingMode || "user";
        const nextFacingMode =
          currentFacingMode === "user" ? "environment" : "user";

        // Stop and close the current video track
        await rtc.localVideoTrack.stop();
        await rtc.localVideoTrack.close();

        // Create a new video track with the opposite facing mode (front/rear camera)
        const newVideoTrack = await AgoraRTC.createCameraVideoTrack({
          facingMode: nextFacingMode, // Toggle between "user" and "environment"
        });

        // Update the rtc object with the new video track
        setRtc((prevRtc) => ({
          ...prevRtc,
          localVideoTrack: newVideoTrack,
        }));

        // Apply the CSS flip if using the front camera (user-facing)
        if (nextFacingMode === "user") {
          localVideoRef.current.style.transform = "scaleX(1)"; // Flip horizontally
        } else {
          localVideoRef.current.style.transform = "scaleX(-1)"; // No flip for rear camera
        }

        // Play the new video track in the video element
        newVideoTrack.play(localVideoRef.current);
      } else {
        toast.error("No video track available to switch.");
      }
    } catch (error) {
      console.error("Failed to switch camera", error);
      toast.error(`Failed to switch camera: ${error.message}`);
    }
  };

  const handleMuteToggle = async () => {
    if (rtc.localAudioTrack) {
      if (isMuted) {
        await rtc.localAudioTrack.setEnabled(true); // Unmute
      } else {
        await rtc.localAudioTrack.setEnabled(false); // Mute
      }
      setIsMuted(!isMuted); // Toggle mute state
    } else {
    }
  };

  useEffect(() => {
    const commentsContainer = commentsRef.current;
    if (commentsContainer) {
      commentsContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (commentsContainer) {
        commentsContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleComments, comments.length]);

  // Scroll to bottom when a new comment is added if the user is already at the bottom
  useEffect(() => {
    if (scrollAtBottom && commentsRef.current) {
      commentsRef.current.scrollTo({
        top: commentsRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [comments, scrollAtBottom]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "auctions/get-auction", {
        params: {
          auction_id: +id,
        },
      })
      .then((response) => {
        console.log(response.data.data)
        setAuction(response.data.data);
        setComments(response.data.data.Auction_comments);
        if(response.data.data.Auction_bids.length){
          setLastBidName(response.data.data.Auction_bids[0].user.name)
          setLastBidNumber(response.data.data.Auction_bids[0].bid)
        }
     
      })
      .catch((error) => {
        toast.error(error.message);
      });
    const socketConnection = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
    });
    setSocket(socketConnection);

    socketConnection.emit("joinAuction", { auctionId: +id, userId: +user.id });
    // Listen for user count updates
    socketConnection.on("userCountUpdate", (data) => {
      setViews(+data.userCount);
    });

    socketConnection.on("newComment", (data) => {
      setComments((prevComments) => [...prevComments, data.newComment]);
    });

    socketConnection.on("newBid", (data) => {
      setLastBidNumber(data.newBid.bid);
      setLastBidName(data.newBid.user.name);
    });

    socketConnection.on("auctionEnded", (data) => {
      setComments(data.auction.Auction_comments);
      setLastBidName("");
      setLastBidNumber("");
      setShowAwardingModal(false);
      console.log(data);
      if (
        +user.id !== +data.auction.user_id &&
        +data.winningUser.id === +user.id
      ) {
        setShowWinningModal(true);
      } else if (
        +user.id !== +data.auction.user_id &&
        +data.winningUser.id !== +user.id
      ) {
        console.log(isBroadcaster);
        console.log(data.winningUser.id);
        console.log(user.id);
        setShowLosingModal(true);
      }
    });

    return () => {
      socketConnection.disconnect();
    };
  }, []);

  useEffect(() => {
    if (auction && user) {
      // Set broadcaster state after auction data is loaded
      setIsBroadcaster(+user.id === +auction.user.id ? "true" : "false");

      // Initialize Agora once we know if the user is a broadcaster or audience
      initAgora(+user.id === +auction.user.id ? "true" : "false");
    }

    return () => {
      // Clean up Agora resources
      if (rtc.localVideoTrack) {
        rtc.localVideoTrack.close();
      }
      if (rtc.client) {
        rtc.client.leave();
      }
    };
  }, [auction, user]);

  return (
    <div>
      <div className="p-0 relative md:p-20">
        {showBidModal ? (
          <BidModal
            bid={bid}
            setBid={setBid}
            handleSubmit={handleBid}
            setShowModal={setShowBidModal}
          />
        ) : null}

        {showAwardingModal ? (
          <AwardingModal
            description={productDescription}
            setDescription={setProductDescription}
            handleSubmit={handleAwarding}
            setShowModal={setShowAwardingModal}
            winner={lastBidName}
          />
        ) : null}

        {showWinningModal ? (
          <WinningModal setShowModal={setShowWinningModal} />
        ) : null}

        {showLosingModal ? (
          <LosingModal setShowModal={setShowLosingModal} />
        ) : null}

        {/* Main auction image */}
        <div className="relative">
          {/* <img
            src={liveAuctionImg}
            alt="Live Auction"
            className="w-full rounded-md"
          /> */}

          <div
            ref={localVideoRef}
            style={{ width: "100%", height: "90vh" }}
            className="rounded-md"
          ></div>

          {/* Exit icon in the top left corner of the image */}
          <div className="absolute top-5 left-5 cursor-pointer flex gap-2">
            {/* <img src={exitVector} alt="exit" /> */}
            <div
              onClick={() => {
                rtc.client.leave();
                navigate("/auctions");
              }}
            >
              <FontAwesomeIcon icon={faCircleXmark} size="2x" color="white" />
            </div>
            {/* <img
              src={walletVector}
              alt="wallet"
              className="ml-2"
               onClick={handleSwitchCamera}
            /> */}
            {isBroadcaster === "true" ? (
              <div onClick={() => handleSwitchCamera()}>
                <FontAwesomeIcon
                  icon={faCameraRotate}
                  size="2x"
                  color="white"
                />
              </div>
            ) : null}

            {/* <img src={profileVector} alt="profile" className="ml-2" /> */}
          </div>

          <div className="absolute top-10 left-5 flex-col mt-10">
            {/* <div
              className="rounded-full p-2 flex gap-3"
              style={{
                background:
                  "linear-gradient(90deg, #CEB991 0%, #CEB991 50%, #42261B 50%, #42261B 100%)",
              }}
            >
              <img src={productVector} alt="product" />
              <div className="text-white text-bold">السلعة</div>
            </div> */}

            <div
              className="rounded-full p-2 flex items-center gap-4 mt-4"
              style={{
                background:
                  "linear-gradient(90deg, #CEB991 0%, #CEB991 50%, #42261B 50%, #42261B 100%)",
              }}
            >
              <svg
                class="h-8 w-8 text-red-500"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                style={{
                  color: "white",
                }}
              >
                {" "}
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2" />{" "}
                <path d="M12 3v3m0 12v3" />
              </svg>

              <div className="text-white font-bold">{lastBidNumber}</div>
            </div>
          </div>

          {/* Auction Creator Avatar and Name in the top right corner of the image */}
          <div className="absolute top-5 right-5 flex items-center">
            <div dir="rtl" className="flex items-center gap-4">
              <img
                className="w-14 h-14 rounded-full"
                src={userProfileImg}
                alt="User Profile"
              />
              <div>
                <div className="font-bold text-white dark:text-white">
                  {auction ? auction.user.name : ""}
                </div>
                <div className="text-sm text-white dark:text-gray-400">
                  {views} مشاهد
                </div>
              </div>
            </div>
          </div>

          <div className="absolute top-12 right-5 mt-12">
            <div
              className="rounded-full p-2 flex gap-2"
              style={{
                background:
                  "linear-gradient(90deg, #FDF9DB66 40%, #FBB650 100%)",
              }}
            >
              <div className="font-semibold text-white">{lastBidName}</div>
              <svg
                class="h-6 w-6 text-red-500"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                style={{
                  color: "white",
                }}
              >
                {" "}
                <circle cx="12" cy="8" r="7" />{" "}
                <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
              </svg>
            </div>
          </div>

          <div className="absolute bottom-10 right-5 mb-20">
            <div className="relative max-h-600 overflow-hidden">
              <div
                ref={commentsRef} // Reference to the comments container
                className="overflow-auto shadow-md dark:bg-gray-800 p-2 rounded-md"
                style={{ height: "200px" }} // Set the height to 200px
              >
                {comments.slice(-visibleComments).map((comment, index) => (
                  <div
                    key={index}
                    dir="rtl"
                    className="flex items-center gap-4 mt-4"
                  >
                    <img
                      className="w-14 h-14 rounded-full"
                      src={userProfileImg}
                      alt="User Profile"
                    />
                    <div>
                      <div className="font-bold text-white dark:text-white">
                        {comment.user.name}
                      </div>
                      <div className="text-sm text-white dark:text-gray-400">
                        {comment.comment}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div dir="rtl" className="absolute bottom-5 right-5 mt-4">
            <div className="flex items-center">
              <img
                className="w-11 h-11 rounded-full"
                src={userProfileImg}
                alt="User Profile"
              />
              <div className="relative mr-2">
                <form onSubmit={(e) => handleComment(e)}>
                  <input
                    type="text"
                    className="border rounded-full pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="اكتب تعليق"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                    <svg
                      class="h-8 w-8 text-red-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      style={{
                        color: "#42261B",
                      }}
                      onClick={handleComment}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
                      />
                    </svg>
                  </span>
                </form>
              </div>
            </div>
          </div>

          {/* {isBroadcaster === "true" ? (
            <div
              data-dial-init
              className="absolute left-6 bottom-20 group"
              style={{ marginBottom: "100px" }}
            >
              
            </div>
          ) : null} */}
          {isBroadcaster === "true" ? (
            <div
              data-dial-init
              className="absolute  group rounded-full p-2"
              style={{
                left: "30px",
                bottom: "170px",
                background: "white",
              }}
              onClick={() => {
                console.log("Clicked");
                handleMuteToggle();
              }}
            >
              {isMuted ? (
                <FaMicrophoneSlash width={50} />
              ) : (
                <FaMicrophone width={50} />
              )}
            </div>
          ) : null}

          {isBroadcaster !== "true" ? (
            <div
              data-dial-init
              className="absolute left-6 bottom-20 group mb-3"
            >
              <button
                type="button"
                data-dial-toggle="speed-dial-menu-default"
                aria-controls="speed-dial-menu-default"
                className="flex flex-col items-center justify-center text-white bg-yellow-700 rounded-full w-14 h-14 hover:bg-yellow-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800"
                style={{
                  backgroundColor: "#42261B",
                  padding: "8px",
                }}
                onClick={handleShowBid}
              >
                <svg
                  className="h-5 w-5 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{
                    color: "white",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div className="text-sm mt-0">مزاودة</div>
              </button>
            </div>
          ) : (
            <div
              data-dial-init
              className="absolute left-6 bottom-20 group mb-3"
            >
              <button
                type="button"
                data-dial-toggle="speed-dial-menu-default"
                aria-controls="speed-dial-menu-default"
                className="flex flex-col items-center justify-center text-white bg-yellow-700 rounded-full w-14 h-14 hover:bg-yellow-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800"
                onClick={handleShowAwarding}
              >
                <img
                  src={vectoryVector}
                  alt="vectory"
                  style={{ margin: "0 auto" }}
                />
                <div className="text-sm mt-0">ترسية</div>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LiveAuction;
